import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Auth, authState, getIdTokenResult, signOut, signInWithEmailLink, isSignInWithEmailLink, getAuth } from "@angular/fire/auth";
import { isNil } from "lodash-es";
import { BehaviorSubject, Observable, combineLatest, firstValueFrom, from, of } from "rxjs";
import { filter, first, map, mergeMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { OrganizationStateService } from "../state/organization.state.service";
import { StateManagerService } from "../state/state-manager.service";
import { TagsStateService } from "../state/tags.state.service";
import { SentryOnUserChange, SentryService } from "./sentry.service";
import { GetCurrentEmployeeResponse, Organization } from "@me-fit-mono/typings";
import { OnboardingStateService } from "../state/onboarding.state.service";
import { Router } from "@angular/router";
import { AlgoliaService } from "./algolia.service";
import { TranslateService } from "@ngx-translate/core";
import { EmployeesStateService } from "../state/employees.state.service";
import { ToastController, AlertController } from "@ionic/angular";
import { showErrorToast } from "../decorators/feedback-decorator";



@Injectable({
  providedIn: "root"
})
export class AuthService {

  getCurrentEmployeeState$ = new BehaviorSubject<GetCurrentEmployeeResponse|null>(null);

  getCurrentEmployeeStateIsLoading$ = new BehaviorSubject<boolean>(false);

  currentEmployee$: Observable<GetCurrentEmployeeResponse|null> = authState(this.auth).pipe(
    filter( user => !isNil(user)),
    mergeMap(() => {
      return this.getCurrentEmployeeState$;
    }),
  );

  selectedOrganizationId$ = new BehaviorSubject<string|null>(localStorage.getItem('currentOrganizationId'));

  /** Currently selected organization */
  organization$ = combineLatest([
    this.selectedOrganizationId$,
    this.currentEmployee$,
  ]).pipe(
    filter(([selectedOrganizationId, currentEmployee]) => !isNil(selectedOrganizationId) && !isNil(currentEmployee)),
    map(([selectedOrganizationId, currentEmployee]) => {
      const organization = currentEmployee?.organizations.find( organization => organization.id === selectedOrganizationId);

      return organization;
    })
  );

  organizations$ = this.currentEmployee$.pipe(
    map( employee => employee?.organizations),
  );

  isLoggedIn$ = combineLatest([
    this.getCurrentEmployeeState$,
    this.getCurrentEmployeeStateIsLoading$
  ]).pipe(
    filter(([_getCurrentEmployeeState, isLoading]) => !isLoading),
    map(([getCurrentEmployeeState]) => {
      console.log('[auth:isLoggedIn$] getCurrentEmployeeState is', getCurrentEmployeeState, `getCurrentOrganizationId ${this.getCurrentOrganizationId()}`);
      const isLoggedIn = !isNil(getCurrentEmployeeState) && !isNil(this.getCurrentOrganizationId());

      return isLoggedIn
    }),
  );

  isAdmin$: Observable<boolean> = this.currentEmployee$.pipe(
    map( employee => employee?.isAdmin ?? false)
  );

  isSuperAdmin$: Observable<boolean> = authState(this.auth).pipe(
    mergeMap( auth => {
      if (isNil(auth)) {
        return of(false);
      }

      return from(
        getIdTokenResult(auth)
          .then( idTokenResult => Boolean(idTokenResult.claims['isAdmin']))
        );
    })
  );

  currentFirebaseUid$ = authState(this.auth).pipe(
    map( auth => auth?.uid)
  );

  /**
   * If a user came in from a deeplink, we want to redirect them to the correct page after login
   */
  postLoginRedirectUrl: string|undefined;

  constructor(
    private auth: Auth,
    private http: HttpClient,
    private $sentry: SentryService,
    private $stateManager: StateManagerService,
    private $organizationState: OrganizationStateService,
    private $tagsState: TagsStateService,
    private $onboarding: OnboardingStateService,
    private $algioa: AlgoliaService,
    private router: Router,
    private $translate: TranslateService,
    private $employee: EmployeesStateService,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController
  ) {
    this.selectedOrganizationId$.subscribe( id => {
      console.log(`[auth:constructor] selectedOrganizationId$ is now ${id}`);

      if (isNil(id)) {
        localStorage.removeItem('currentOrganizationId');
      } else {
        localStorage.setItem('currentOrganizationId', id);
      }
    })
  }

  getCurrentOrganizationId(): string|null {
    return this.selectedOrganizationId$.value;
  }

  setCurrentOrganizationId(id: string) {
    this.selectedOrganizationId$.next(id);
  }

  unsetCurrentOrganizationId() {
    this.selectedOrganizationId$.next(null);
  }

  getLoggedInState(): Observable<boolean> {
    if (isNil(this.getCurrentOrganizationId())) {
      console.log(`[auth:getLoggedInState] No organization is selected, returning early with false`);

      return of(false);
    }

    return this.isLoggedIn$.pipe(
      first()
    );
  }

  async fetchCurrentEmployee() {
    this.getCurrentEmployeeStateIsLoading$.next(true);

    const currentFirebaseUser = await authState(this.auth).pipe(first()).toPromise();

    if (isNil(currentFirebaseUser)) {
      console.warn('[AuthService:fetchCurrentEmployee] No firebase user is logged in. returning early');

      this.getCurrentEmployeeStateIsLoading$.next(false);

      return;
    }

    const promise = firstValueFrom(
      this.http.get<GetCurrentEmployeeResponse>(`${environment.API_ENDPOINT}/employees/getCurrent`, {
        observe: 'response'
      })
    );

    try {
      const httpResponse = await promise;

      const response = httpResponse.body;

      if (!response) {
        throw new Error('No response');
      }

      this.getCurrentEmployeeState$.next(response);

      const sentryUser: SentryOnUserChange = {
        userEmail: response.email,
        userId: response.id,
        isAdmin: response.isAdmin,
      }

      const currentOrganizationId = this.getCurrentOrganizationId();
      // Whenever we fetch the user, and there is an organization id set we want to fetch the following
      if (!isNil(currentOrganizationId)) {
        this.$organizationState.getNotificationsEvents.call();
        this.$tagsState.list.call();
        this.$onboarding.status.call();
        this.$organizationState.getCustomFields.call();
        this.$organizationState.getExerciseFavorites.call();
        this.$employee.getExerciseFavorites.call();

        const matchingOrganization = response.organizations.find(organization => organization.id === currentOrganizationId)!;

        this.$sentry.onUserChange({
          ...sentryUser,
          organizationId: currentOrganizationId,
          organizationName: matchingOrganization!.name,
        });

        // We know it will be there given the check above surrounding the currentOrganizationId
        this.$algioa.init({
          algoliaSecuredApiKey: response.algoliaSecuredApiKey!,
          organizationId: currentOrganizationId,
        });


        this.setOrganizationSpecificTranslations(matchingOrganization.organizationType);

      } else {
        this.$sentry.onUserChange(sentryUser);
      }
    } catch (httpErrorResponse) {
      if (httpErrorResponse instanceof HttpErrorResponse && httpErrorResponse.status === 400) {
        // This means the selected organization is no longer valid
        this.unsetCurrentOrganizationId();
      }
      this.getCurrentEmployeeState$.next(null);
    } finally {
      this.getCurrentEmployeeStateIsLoading$.next(false);
    }

    return promise;
  }

  async logout() {
    await signOut(this.auth);

    this.unsetCurrentOrganizationId();

    this.getCurrentEmployeeState$.next(null);

    this.$stateManager.clearAllState();


    this.router.navigate(['login'], { replaceUrl: true });
  }

  async setOrganizationSpecificTranslations(organizationType: Organization['organizationType']) {
    const isSportOrganization = organizationType === 'sport';
    const userLanguage = 'en';

    if (!isSportOrganization) {
      return;
    }

    try {
      const translationImport: Promise<Record<string, string>> = await import(`../../assets/i18n/${userLanguage}-sport.json`);

      const translationJson = JSON.parse(JSON.stringify(translationImport));

      // For sport organizations, we will override some of the translations
      this.$translate.setTranslation(userLanguage, translationJson, true);
    } catch {
      console.error(`[AuthService:setOrganizationSpecificTranslations] Could not load translations for ${userLanguage}-sport.json`);
    }

  }


  /**
   * @see https://firebase.google.com/docs/auth/web/email-link-auth
   */
  async checkEmailLinkSignIn() {
    // Confirm the link is a sign-in with email link.
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email: string | undefined | null = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = await this.requestEmail().catch(() => undefined);
      }

      if (!email) {
        return;
      }

      // The client SDK will parse the code from the link for you.
      try {
        const result = await signInWithEmailLink(auth, email!, window.location.href);
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');

        return result;
      } catch (error) {


        if (error.code === "auth/invalid-email") {
          showErrorToast({
            message: this.$translate.instant('login.wrong.email'),
          })
        } else {
          showErrorToast({
            message: this.$translate.instant('generic.error.message'),
          })
        }

        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        console.error('Error signing in with email link', error);
      }
    }
  }

  /**
   * Helper method for sign in with email
   */
  async requestEmail() {

    const header = await firstValueFrom(this.$translate.get('login.email.alert.title'));
    const message = await firstValueFrom(this.$translate.get('login.email.alert.message'));
    const placeholder = await firstValueFrom(this.$translate.get('email'));
    const cancelText = await firstValueFrom(this.$translate.get('action.cancel'));
    const confirmText = await firstValueFrom(this.$translate.get('login.short'));

    const alert = await this.alertCtrl.create({
      header,
      message,
      inputs: [{
        name: 'email',
        type: 'email',
        attributes: {
          autocomplete: 'email'
        },
        placeholder
      }],
      buttons: [{
        text: cancelText,
        role: 'cancel'
      }, {
        text: confirmText,
        role: 'confirm'
      }]
    });

    alert.present();

    const event = await alert.onDidDismiss();

    console.log(event);

    if (event.role === 'cancel') {
      return;
    }

    const missingEmailMessage = await firstValueFrom(this.$translate.get('email.missing'));

    const emailValidateToast = await this.toastCtrl.create({
      message: missingEmailMessage,
      duration: 3000
    });


    const email: string = event.data.values.email;

    if (!email) {
      emailValidateToast.present();

      return;
    }

    return email;
  }
}
