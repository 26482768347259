import { Injectable } from "@angular/core";
import { FindAllSharedWorkoutResponse, FindAllTemplatesResponse, WorkoutSearchResponse, WorkoutsSearchRequest, GetWorkoutsResponse, GetWorkoutGroupsResponse, CreateWorkoutDto, UpdateWorkoutDto, WorkoutFindAllSharedFilters, WorkoutFindAllTemplatesFilters, BulkWorkoutArchiveRequest, UpdateWorkoutArchiveRequest, GetCustomFieldsResponse, WorkoutStepCustomFieldWithValue, SuccessResponse, BulkSharedWorkoutsArchiveRequest } from "@me-fit-mono/typings";
import { isNil } from "lodash-es";
import { ME_Fit } from "me-fit-typings";
import { environment } from "src/environments/environment";
import { Optional } from "../types";
import { WorkoutShareResponse } from "../typings/me-fit-backend.typings";
import { StateManagerService } from "./state-manager.service";
import { OnboardingStateService } from "./onboarding.state.service";
import { OrganizationStateService } from "./organization.state.service";
import { CustomersStateService } from "./customers.state.service";

export interface WorkoutStepData {
  exerciseId: string;
  value: number;
  break: number;
  sets: number;
  weightValue?: number;
  type: ME_Fit.WorkoutStepType;
  superSet?: string | null;
  notes?: string;

  /** Only available when editing a workout */
  id?: string;

  customFields?: {
    [workoutStepCustomFieldId: string]: unknown;
  }
}

type WorkoutIdPathPart = { workoutId: string };


@Injectable({
  providedIn: "root"
})
export class WorkoutStateService {
  private API_WORKOUTS = `${environment.API_ENDPOINT}/organizations/workouts`;

  findAllTemplates = this.$state.createState<WorkoutFindAllTemplatesFilters, FindAllTemplatesResponse>({
    httpMethod: "post",
    path: `${this.API_WORKOUTS}/templates`,
  });

  findAllShared = this.$state.createState<WorkoutFindAllSharedFilters, FindAllSharedWorkoutResponse>({
    httpMethod: "post",
    path: `${this.API_WORKOUTS}/shared`,
  });

  searchWorkouts = this.$state.createState<WorkoutsSearchRequest, WorkoutSearchResponse>({
    httpMethod: "post",
    path: `${this.API_WORKOUTS}/search`,
  });

  get = this.$state.createState<string, GetWorkoutsResponse, WorkoutIdPathPart>({
    httpMethod: "get",
    path: `${this.API_WORKOUTS}/:workoutId`,
  });

  update = this.$state.createState<UpdateWorkoutDto, ME_Fit.Workout, WorkoutIdPathPart>({
    httpMethod: "patch",
    path: `${this.API_WORKOUTS}/:workoutId`,
    onNewResponse: (state) => {
      this.get.call({ pathParts: { workoutId: state.pathParts?.workoutId! } });

      this.onWorkoutChange(state.response)
    },
  });

  create = this.$state.createState<CreateWorkoutDto, ME_Fit.Workout>({
    httpMethod: "post",
    path: this.API_WORKOUTS,
    onNewResponse: (state) => {
      this.onWorkoutChange(state.response);

      // This is needed as the onboarding status is not updated when a new workout is created
      this.$onboarding.status.call();
    }
  });

  updateWorkoutArchiveState = this.$state.createState<UpdateWorkoutArchiveRequest, ME_Fit.Workout, WorkoutIdPathPart>({
    httpMethod: "patch",
    path: `${this.API_WORKOUTS}/:workoutId/archive`,
    onNewResponse: (state) => {
      this.onWorkoutChange(state.response);

      // This is needed as the onboarding status is not updated when a new workout is archived
      this.$onboarding.status.call();
    }
  });

  duplicate = this.$state.createState<void, ME_Fit.Workout, WorkoutIdPathPart>({
    httpMethod: "post",
    path: `${this.API_WORKOUTS}/duplicate/:workoutId`,
  });

  /**
   * Gets customers this workout was already shared with, does not take duplicates into account
   * */
  getWorkoutCustomers = this.$state.createState<string, ME_Fit.OrganizationCustomer[], WorkoutIdPathPart>({
    httpMethod: "get",
    path: `${this.API_WORKOUTS}/:workoutId/customers`,
  });

  getWorkoutGroups = this.$state.createState<string, GetWorkoutGroupsResponse, WorkoutIdPathPart>({
    httpMethod: "get",
    path: `${this.API_WORKOUTS}/:workoutId/groups`,
  });


  /** Will share a workout WITHOUT duplication, and is meant to support old use cases */
  legacyShareWorkout = this.$state.createState<{ workoutId: string; organizationCustomerIds: string[] }, WorkoutShareResponse>({
    httpMethod: "post",
    path: `${this.API_WORKOUTS}/share`
  });

  turnLegacyWorkoutIntoTemplateWorkout = this.$state.createState<string, ME_Fit.Workout, WorkoutIdPathPart>({
    httpMethod: "post",
    path: `${this.API_WORKOUTS}/turn-legacy-workout-into-template-workout/:workoutId`,
    onNewResponse: (state) => this.onWorkoutChange(state.response)
  });

  bulkArchive = this.$state.createState<BulkWorkoutArchiveRequest, void>({
    httpMethod: "patch",
    path: `${this.API_WORKOUTS}/bulk-archive`,
    onNewResponse: () => {
      // At the moment, we can only archive template workouts, so we need to reload only template workouts
      this.findAllTemplates.call();
    }
  });

  bulkArchiveSharedWorkouts = this.$state.createState<BulkSharedWorkoutsArchiveRequest, SuccessResponse>({
    httpMethod: "patch",
    path: `${this.API_WORKOUTS}/shared/bulk-archive`,
    onNewResponse: ({response}) => {
      if (!response?.success) {
        return
      }
      // Re-fetch shared workouts, get current customer
      const { response: currentCustomer } = this.$customerState.get.state$.value;

      if (!currentCustomer) {
        return;
      }

      this.$organizationState.getOrganizationCustomerContractWorkouts.call({
        pathParts: {
          contractId: currentCustomer.organizationCustomerContract.id
        }
      });
    }
  });

  bulkSelectTags = this.$state.createState<{ workoutIds: string[]; tagIds: string[] }, void>({
    httpMethod: "patch",
    path: `${this.API_WORKOUTS}/bulk-select-tags`,
    onNewResponse: () => {
      // At the moment, we can only bulk tag template workouts, so we need to reload only template workouts
      this.findAllTemplates.call();
    }
  });

  findAllTemplatesDefaultFilters: WorkoutFindAllTemplatesFilters = {
    workoutTags: [],
    archivedFilter: 'active',
  }

  constructor(
    private $state: StateManagerService,
    private $onboarding: OnboardingStateService,
    private $organizationState: OrganizationStateService,
    private $customerState: CustomersStateService
  ) { }


  onWorkoutChange(changedWorkout?: ME_Fit.Workout) {
    if (isNil(changedWorkout)) {
      // If we are not sure what workout was changed, we need to reload all workouts
      this.findAllTemplates.call({
        request: this.findAllTemplates.state$.value.request ?? this.findAllTemplatesDefaultFilters
      });
      this.findAllShared.call();

      return;
    }

    if (changedWorkout.isTemplate) {
      this.findAllTemplates.call({
        request: this.findAllTemplates.state$.value.request ?? this.findAllTemplatesDefaultFilters
      });

    } else {
      this.findAllShared.call();
    }
  }
}
