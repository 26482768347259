import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash-es';
import InjectorWrapper from './injector-wrapper';
import { TranslationKey } from '../typings/english-translation-keys';


/**
 * Parameters pertaining to the alert dialogue
 *
 * Title, message, ok and cancel button translations
 */
export interface IConfirmAlertParams {
  i18nTitleKey: TranslationKey;
  i18nMessageKey: TranslationKey;
  i18nCancelButtonKey?: TranslationKey;
  i18nConfirmButtonKey: TranslationKey;
}

/**
 * Role assigned to the Confirm button
 */
const USER_CONFIRMATION_ROLE = 'confirm';

/**
 * Role assigned to the Cancel button
 */
const USER_CANCELLATION_ROLE = 'cancel';

/**
 * Encapsulate Confirmation Alert logic
 *
 * Returns a boolean value corresponding to whether the user confirmed the alert or not
 */
export async function presentConfirmAlert(input: IConfirmAlertParams): Promise<boolean> {

  // Get the dependencies needed for showing the alert
    //
  const alertCtrl = InjectorWrapper.getInjector().get(AlertController);
  const $translate = InjectorWrapper.getInjector().get(TranslateService);

  const alert = await alertCtrl.create({
    header: $translate.instant(input.i18nTitleKey),
    message: $translate.instant(input.i18nMessageKey),
    buttons: [{
      role: USER_CANCELLATION_ROLE,
      text: $translate.instant(input.i18nCancelButtonKey || 'action.cancel')
    }, {
      role: USER_CONFIRMATION_ROLE,
      cssClass: 'alert-danger',
      text: $translate.instant(input.i18nConfirmButtonKey),
    }]
  });

  alert.present();

  const dismissedPromise = alert.onDidDismiss();

  // Await for the alert promise
  //
  const result = await dismissedPromise;

  // Check if the user preseed the confirm button
  //
  const userConfirmed = result.role === USER_CONFIRMATION_ROLE;

  return userConfirmed;
}

/**
 * Add this decorator to any method you want the user to verify, so any method that will perform a destructive action.
 */
export function ConfirmAlert(params: IConfirmAlertParams) {
  return function (_target: any, _prop: string, descriptor: TypedPropertyDescriptor<(...args: any[]) => Promise<any>>) {
    const method = descriptor.value;

    descriptor.value = function () {
      const args: IArguments = arguments;

      // Use the utility function to present the confirmation alert
      //
      const presentConfirmAlertPromise = presentConfirmAlert(params);

      return presentConfirmAlertPromise.then( (userConfirmed: boolean) => {
        if ( userConfirmed && !isNil(method)) {
          const returnValue = method.apply(this, args);

          return returnValue;
        }
      });
    };
  };
}
