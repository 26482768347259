import { Injectable } from "@angular/core";
import { GroupType, WorkoutsSearchRequest } from "@me-fit-mono/typings";
import { TranslateService } from "@ngx-translate/core";
import { ME_Fit } from "me-fit-typings";
import { first } from "rxjs/operators";
import { InlineSearchItem } from "../modules/components/inline-search/inline-search.component";
import { FusejsService } from "../modules/fusejs/fusejs.service";
import { CustomersStateService } from "../state/customers.state.service";
import { GroupsStateService } from "../state/groups.state.service";
import { WorkoutStateService } from "../state/workout.state.service";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { showErrorToast } from "../decorators/feedback-decorator";

interface CustomersItem extends InlineSearchItem<ME_Fit.OrganizationCustomer & { type: 'customer' }> { }

interface GroupsItem extends InlineSearchItem<ME_Fit.OrganizationGroup & { type: 'group' }> { }

export type InlineSearchItemCustomersAndGroups = CustomersItem | GroupsItem;

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(
    private $workoutState: WorkoutStateService,
    private $fusejs: FusejsService,
    private $customersState: CustomersStateService,
    private $groupsState: GroupsStateService,
    private $translate: TranslateService,
  ) { }

  /**
   * This will use fusejs to search workouts as initial implementation, when this becomes slow
   * we will need to implement a search endpoint in the backend
   */
  searchWorkouts = async (query: string, params?: WorkoutsSearchRequest): Promise<InlineSearchItem[]> => {
    const workoutsResponse = await this.$workoutState.searchWorkouts.call({
      // Extremely important, we dont want to be re-fetching every search
      getLastResponse: true,
      request: params ?? {
        includeIsTemplate: true,
        customerIds: [],
        groupIds: [],
      }
    });

    const workouts: InlineSearchItem[] = workoutsResponse.map(workout => {
      let title = workout.name;

      return {
        title: title,
        badge: workout.isTemplate ? {
          text: this.$translate.instant('workout.template.title'),
          color: 'secondary'
        } : undefined,
        subtitle: workout.description,
        id: workout.id,
        rawObject: workout,
      } satisfies InlineSearchItem;
    });

    const filteredWorkouts = this.$fusejs.searchList(workouts, query, {
      keys: ['title'] as Array<keyof InlineSearchItem>
    });

    return filteredWorkouts;
  }

  searchGroups = async (query: string): Promise<InlineSearchItem[]> => {
    // TODO, if we change a group this will break as its fetching outdated information
    // replace this with a backend implementation instead.
    const groupsResponse = await this.$groupsState.list.call({
      // Extremely important, we dont want to be re-fetching every search
      getLastResponse: true
    });

    const groups: InlineSearchItem[] = groupsResponse.map(group => ({
      title: group.name,
      subtitle: group.groupType == GroupType.regular ? this.$translate.instant('total.customers', { total: group._count.customers }) : '',
      id: group.id,
      rawObject: group,
    }));

    const filteredGroups = this.$fusejs.searchList(groups, query, {
      keys: ['title'] as Array<keyof InlineSearchItem>
    });

    return filteredGroups;
  }

  searchCustomers = async (query: string): Promise<InlineSearchItem[]> => {
    const customersResponse = await this.$customersState.list.call({
      // Extremely important, we dont want to be re-fetching every search
      getLastResponse: true
    });

    const customers: InlineSearchItem[] = customersResponse.map(customer => ({
      title: `${customer.firstName} ${customer.lastName}`,
      id: customer.id,
      rawObject: customer,
    }));

    const filteredCustomers = this.$fusejs.searchList(customers, query, {
      keys: ['title'] satisfies Array<keyof InlineSearchItem>
    });

    return filteredCustomers;
  }

  searchCustomersAndGroups = async (query: string): Promise<InlineSearchItemCustomersAndGroups[]> => {
    // If customers are loading, lets wait for the loading to finish before we fetch
    await firstValueFrom(this.$customersState.list.isLoading$().pipe(
      first(isLoading => !isLoading)
    ));

    try {
      const customers = await this.$customersState.list.call({
        // Extremely important, we dont want to be re-fetching every search
        getLastResponse: true
      });

      const groups = await this.$groupsState.list.call({
        // Extremely important, we dont want to be re-fetching every search
        getLastResponse: true
      });

      const customerSearchItems: InlineSearchItem[] = customers.map(customer => ({
        title: `${customer.firstName} ${customer.lastName}`,
        id: customer.id,
        rawObject: { ...customer, type: 'customer' },
      }));

      const groupSearchItems: InlineSearchItem[] = groups.map(group => ({
        title: group.name,
        id: group.id,
        subtitle: group.groupType == GroupType.regular ? this.$translate.instant('total.customers', { total: group._count.customers }) : '',
        rawObject: { ...group, type: 'group' },
        badge: {
          color: 'secondary',
          text: this.$translate.instant('group')
        }
      }));


      const allSearchItems: InlineSearchItem[] = [
        ...groupSearchItems,
        ...customerSearchItems,
      ];

      const filteredCustomersAndGroups = this.$fusejs.searchList(allSearchItems, query, {
        keys: ['title'] as Array<keyof InlineSearchItem>
      });

      return filteredCustomersAndGroups;
    } catch (error) {
      console.error('[SearchService] searchCustomersAndGroups', error);

      showErrorToast({
        message: this.$translate.instant('search.client.and.group.fail'),
        duration: 3000,
      });

      return [];
    }
  }

}
