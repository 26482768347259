<ion-header>
  <ion-toolbar>
    <ion-title> {{ 'customer.add.title' | translate }} </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <form [formGroup]="customerCreateForm" (ngSubmit)="onAddCustomerFormSubmit()">
    <ion-item fill="outline">
      <ion-label position="floating">{{ 'first.name' | translate }}</ion-label>
      <ion-input type="text" formControlName="firstName"></ion-input>
    </ion-item>
    <ion-item fill="outline" class="ion-margin-top">
      <ion-label position="floating">{{ 'last.name' | translate }}</ion-label>
      <ion-input type="text" formControlName="lastName"></ion-input>
    </ion-item>

    <me-inline-search ionLabelPosition="floating" [searchFn]="groupSearchFn" [searchLabel]="'customer.create.add.group' | translate" [placeholder]="'groups.search' | translate"
      ionItemFill="outline"  (itemSelected)="createCustomerGroupSelected($event)" class="ion-margin-top"></me-inline-search>


    <me-calendar-group-list class="ion-margin-top" [groups]="selectedGroupsForCreateCustomer" [showNavigationButton]="false" [showGroupExpandButton]="false" [showRemoveButton]="true" (onGroupRemove)="createCustomerGroupRemoved($event)"
      ></me-calendar-group-list>

    <button type="submit" hidden></button>
  </form>
</ion-content>

<ion-footer class="ion-padding buttons-container">
  <ion-row class="ion-justify-content-end">
    <ion-button fill="outline" class="ion-margin-end" (click)="dismiss()">
      {{'action.cancel' | translate}}</ion-button>
    <ion-button color="secondary" (click)="addCustomer(customerCreateForm.value)"
      [disabled]="customerCreateForm.invalid">{{'customer.add.title' | translate}}</ion-button>
  </ion-row>
</ion-footer>
