import { Injectable } from "@angular/core";
import { SharedWorkoutResponse, OrganizationNotification, SuccessResponse, UpdateCustomerWorkoutOrderRequest, GetCustomFieldsResponse, Organization, GetExerciseFavoritesResponse, BulkWorkoutArchiveRequest} from "@me-fit-mono/typings";
import { environment } from "src/environments/environment";
import { StateManagerService } from "./state-manager.service";

type PathPartContractId = {
  contractId: string;
}

type PathPartExerciseId = {
  exerciseId: string;
}

@Injectable({
  providedIn: "root"
})
export class OrganizationStateService {
  private API_ORGANIZATIONS = `${environment.API_ENDPOINT}/organizations`;

  getOrganizationCustomerContractWorkouts = this.$state.createState<void, SharedWorkoutResponse, PathPartContractId>({
    httpMethod: 'get',
    path: `${this.API_ORGANIZATIONS}/customer-contract/:contractId/workouts`,
  });

  archiveWorkout = this.$state.createState<void, unknown, { sharedWorkoutId: string }>({
    httpMethod: 'patch',
    path: `${this.API_ORGANIZATIONS}/workouts/archive/:sharedWorkoutId`,
  });

  unarchiveWorkout = this.$state.createState<void, unknown, { sharedWorkoutId: string }>({
    httpMethod: "patch",
    path: `${this.API_ORGANIZATIONS}/workouts/unarchive/:sharedWorkoutId`,
  });

  updateSharedWorkoutsOrder = this.$state.createState<UpdateCustomerWorkoutOrderRequest, SuccessResponse, PathPartContractId>({
    httpMethod: 'patch',
    path: `${this.API_ORGANIZATIONS}/contract/:contractId/workout/order`,
  })

  update = this.$state.createState<Partial<Organization>, Organization>({
    httpMethod: 'patch',
    path: `${this.API_ORGANIZATIONS}/:organizationId`,
  });

  delete = this.$state.createState<void, void>({
    httpMethod: 'delete',
    path: `${this.API_ORGANIZATIONS}`,
  });

  getNotificationsEvents = this.$state.createState<void, OrganizationNotification[]>({
    httpMethod: 'get',
    path: `${this.API_ORGANIZATIONS}/notifications-events`,
  });

  getCustomFields = this.$state.createState<void, GetCustomFieldsResponse>({
    httpMethod: "get",
    path: `${this.API_ORGANIZATIONS}/custom-fields`,
  });

  // New functions for favorite API endpoints
  getExerciseFavorites = this.$state.createState<void, GetExerciseFavoritesResponse>({
    httpMethod: "get",
    path: `${this.API_ORGANIZATIONS}/exercises/favorites`,
  });

  requestExport = this.$state.createState<void, SuccessResponse>({
    httpMethod: "post",
    path: `${this.API_ORGANIZATIONS}/export/request`,
  });

  constructor(private $state: StateManagerService) { }
}
